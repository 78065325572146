p {
  @include text-and-bold-size(s, 0.75rem, 1rem, 600);
  @include text-and-bold-size(m, 0.875rem, 1.25rem, 600);
  @include text-and-bold-size(l, 1rem, 1.5rem, 700);
  @include text-and-bold-size(xl, 1.125rem, 1.5rem, 600);
  @include text-and-bold-size(xxl, 1.25rem, 1.75rem, 600);

  &.link {
    @include text-size(1rem, 1.875rem, 700);
  }
  strong {
    font-weight: 600;
  }
}