﻿label {
  display: inline;

  &.xs-bold {
    @include text-size(0.875rem, 1.25rem, 600);
  }
  &.xs {
    @include text-size(0.75rem, 0.875rem, 400);
    &.semi-bold {
      font-weight: 600;
    }
  }
  &.xs-badge {
    @include text-size(0.75rem, 0.875rem, 600);
  }
  &.s {
    @include text-size(0.875rem, 1.25rem, 600);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.0175em;
  }
  &.s-caps {
    @include text-size(0.75rem, 1.125rem, 600);
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }
  &.m {
    @include text-size(0.875rem, 1.5rem, 600);
    letter-spacing: 0.018em;
  }
  &.m-bold {
    @include text-size(0.875rem, 1.5rem, 700);
    letter-spacing: 0.018em;
  }
  &.m-caps {
    @include text-size(0.875rem, 1.5rem, 700);
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }
  &.l {
    @include text-size(1rem, 1.5rem, 600);
    letter-spacing: 0.04em;
  }
  &.l-bold {
    @include text-size(1rem, 1.5rem, 700);
    letter-spacing: 0.04em;
  }
  &.xl {
    @include text-size(1.125rem, 1.5rem, 500);
  }
}
