@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import 'material-icons/iconfont/material-icons';
@import 'theme-colors';

// Variables

// Box-Shadows
$box-shadow-hovered: 0 1px 2px 0 rgba($shadow, 0.3), 0 1px 3px 1px rgba($shadow, 0.15) !default;
$box-shadow-primary-focused: inset 0px 0px 0px 2px $primary, 0px 0px 8px 0px $primary !default;
$box-shadow-secondary-focused: inset 0px 0px 0px 2px $secondary, 0px 0px 8px 0px $secondary !default;
$box-shadow-tertiary-focused: inset 0px 0px 0px 1.5px $primary-0, 0px 0px 8px 0px $primary !default;
$box-shadow-tertiary-floating-focused: 0px 0px 8px 0px $primary !default;
$box-shadow-neutral-floating-focused: 0px 0px 8px 0px $neutral-20 !default;
$box-shadow-single-digit-input-focused: 0px 0px 0px 2.5px rgba($primary, 0.4) !default;
$box-shadow-single-digit-input-invalid: 0px 0px 0px 3px rgba($error, 0.3) !default;

// End Variables

// Mdb Angular style overrides //

// Theme colors
$primary: $primary;
$secondary: $secondary;
$tertiary: $tertiary;
$success: $primary;
$info: #39c0ed;
$warning: $tertiary-30;
$danger: $error;
$light: $neutral-100;
$dark: $neutral-0;

$theme-colors: ( 'primary': $primary, 'secondary': $secondary, 'tertiary': $tertiary, 'success': $success, 'info': $info, 'warning': $warning, 'danger': $danger, 'light': $light, 'dark': $dark, 'white': $neutral-100, 'black': $neutral-0, ) !default;
$alerts: () !default;
$alerts: map-merge(
	(
		'ls-success': (
				'background-color': $primary-container,
				'text-color': $primary,
				'icon-color': $primary,
				'border-color': $primary,
		)
	),
	$alerts
);

$font-family-sans-serif: 'Montserrat', sans-serif !default;
$input-btn-font-family: 'Montserrat', sans-serif !default;

$btn-box-shadow: none !default;
$btn-hover-box-shadow: $box-shadow-hovered !default;
$btn-focus-box-shadow: none !default;
$btn-active-box-shadow: none !default;

$line-height-base: 1.5;

// Form
//// Input
$input-focus-active-label-transform-lg: translateY(-1.5rem) translateY(.1rem) scale(.8);
//// Height
$input-height-lg: 3.5rem !important;
//// Borders
$form-notch-div-border-color: $outline;
//// Labels
$form-label-color: $on-surface-variant;
$form-label-padding-top-lg: 1rem;
//// Select
$form-outline-select-arrow-color: $on-surface-variant;
$form-outline-select-dropdown-bgc: $surface;
$form-outline-select-arrow-font-size: 1rem;
$form-outline-select-arrow-top: 0.75rem;

$form-outline-select-sm-arrow-font-size: 0.5rem;
$form-outline-select-sm-arrow-top: 0rem;

// Breakpoints
$grid-breakpoints: ( xs: 0, sm: 768px, md: 992px, lg: 1120px, xl: 1280px, xxl: 1440px );

$table-cell-padding-y:        .5rem;
$table-cell-padding-x:        .5rem;
$table-cell-padding-y-sm:     .25rem;
$table-cell-padding-x-sm:     .25rem;
$table-border-color:          $outline-38;
$table-color:                 $neutral-10;

$nav-tabs-link-padding-top:   .75rem;
$nav-tabs-link-padding-x:     .938rem;  
$nav-tabs-link-padding-bottom: 1.563rem;
$nav-tabs-link-font-weight: bold;
$nav-tabs-link-font-size: .875rem;
$nav-tabs-link-color: $neutral-0;
$nav-tabs-border-width: .063rem;
$nav-tabs-border-color: $outline-38;
$enable-grid-classes: false;
$enable-cssgrid: true;
$tooltip-inner-color: $inverse-on-surface;
$tooltip-inner-background-color: $inverse-surface;
$tooltip-inner-border-radius: 0.5rem;
$tooltip-inner-font-size: 0.75rem;
$table-hover-bg: $on-surface-8;
$table-active-bg: $on-surface-12;
// Mdb Angular style overrides //

// Mixins

@mixin router-outlet-display {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
}

@mixin text-size($size, $height, $weight) {
		font-weight: $weight;
		font-size: $size;
		line-height: $height;
}
	
@mixin text-and-bold-size($name, $size, $height, $bold-weight) {
		&.#{$name} {
				@include text-size($size, $height, 400);  
		}
	
		&.#{$name}-bold {
				@include text-size($size, $height, $bold-weight);
		}
}

@mixin background-and-color($background, $color) {
  background: $background;
  color: $color;
}

@import "../Elements/styles/utilities.scss";
@import "mdb-angular-ui-kit/assets/scss/mdb.scss";
@import "../Elements/styles/button.scss";
@import "../Elements/styles/badge.scss";
@import "../Elements/styles/breadcrumbs.scss";
@import "../Elements/styles/hyperlink.scss";
@import "../Elements/styles/label.scss";
@import "../Elements/styles/cards.scss";
@import "../Elements/styles/ls-icons.scss";
@import "../Elements/styles/headers.scss";
@import "../Elements/styles/title.scss";
@import "../Elements/styles/radio.scss";
@import "../Elements/styles/scroll.scss";
@import "../Elements/styles/nav.scss";
@import "../Elements/styles/modal.scss";
@import "../Elements/styles/paragraph.scss";
@import "../Elements/styles/table.scss";
@import "../Elements/styles/list.scss";
@import "../Elements/styles/salesforce-support-tool.scss";

html,
body {
		height: 100%;    
		font-family: "Montserrat", sans-serif;
		color: $on-background;
		margin: 0;
}

.ls-content-spacing {
		margin: 2.5rem 2.5rem 0 2.5rem;
}
$stepper-width: 19rem;
$mobile-stepper-height: 5.688rem;
$nav-width: 20.5rem;

/*mdb styles start*/
mdb-form-control > mdb-error {
		font-size: 0.75rem;
		margin-top: 1rem;
		margin-bottom: 0.5rem;
}

mdb-tabs > .nav.nav-tabs {
		gap: 2rem;
		border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
}
/*mdb styles end*/

.form-outline .validate-error.ng-invalid.ng-dirty,
.form-outline .validate-error.ng-invalid.ng-touched {
		&.select .select-arrow {
				color: $error;
		}
}

.form-outline.ls-pagination {
	& .select-arrow {
		font-size: 0.75rem;
		top: 0.5rem;
  }
	& .select-input {
		margin-top: 0;
	}
}

.select-dropdown-container {
		margin-top: 0.725rem;
}
.form-control.select-input {
		text-overflow: ellipsis;
		margin-top: 0.25rem;
}
.select-options-wrapper {
		.option {
				background-color: $on-primary;
				color: $on-surface;
				&:hover:not(.disabled) {
						background-color: $on-surface-8;
						color: $on-primary-container;
				}
				&:focus:not(.disabled) {
						background-color: $on-surface-12;
						color: $on-surface;
				}
				&.selected.active {
						background-color: $primary-disabled;
						color: $on-surface;
						&:hover:not(.disabled) {
								background-color: $on-surface-8;
								color: $on-primary-container;
						}
						&:focus:not(.disabled) {
								background-color: $on-surface-12;
								color: $on-primary-container;
						}
				}
		}
}
/*mdb styles end*/


.form-check-input {
		border-color: $on-surface;
		min-width: 1.125rem;
		min-height: 1.125rem;
}

.no-text-transform {
		text-transform: none;
}

.uppercase-text-transform {
		text-transform: uppercase;
}

.no-indent-list {
		padding-left: 0;
}

.hoverable {
		cursor: pointer;
}

.search-input {
		display: flex !important;
		height: 3.5rem;
		padding: 1rem 1rem 1rem 3rem !important;

		& ~ .search-icon {
				top: 1rem;
				left: 1rem;
				position: absolute;
				z-index: 1;
		}

		& ~ .search-label {
				padding: 1rem 1rem 1rem 2.25rem !important;
		}
		
		&:focus ~ .search-label {
				background-color: transparent;
				left: 0 !important;
				padding: .5rem 1rem !important;
				transform-origin: inherit !important;
				transition: left .2s ease-out, transform .2s ease-out, top .2s ease-out !important;
				z-index: 1;
		}

		&.active ~ .search-label {
				margin-left: 0 !important;
		}
}

.light-text {
		color: $on-surface-38;
}

body.successful-application-gradient {
   background: radial-gradient($primary-80, rgba($primary-container,0) ) !important;
}
.inverse-background {
		background-color: $inverse-on-surface;
}

.validation-error {
		color: $error;
}

@include media-breakpoint-down(md) {
	.ls-content-spacing {
			margin-left: 2rem;
			margin-right: 2rem;
	}
}

@include media-breakpoint-down(sm) {
		.ls-content-spacing {
				margin-left: 1rem;
				margin-right: 1rem;
		}
}

.option{
		white-space: normal!important;
		height: 100%!important;
		padding: 1rem!important;
}

.half-max-width {
	max-width: 29.75rem;
}

.always-active-label .form-label {
	transform: translateY(-1rem) translateY(0.1rem) scale(.8);
  }
  
.always-active-label .form-notch .form-notch-middle {
	border-right: none;
	border-left: none;
	border-top: 1px solid transparent !important;  
  }