﻿
.ls-nav-option {
  width: 17.5rem;
  height: 2.5rem;
  &:hover {
    cursor: pointer;
  }

  &.active {
    background-color: $primary-disabled;
    border-radius: 8px;
  }

  label.ls-nav-item {
    color: white;
    display: flex;
    padding-left: 1rem;
    
    &:hover {
      color: $primary-container;
      cursor: pointer;
    }

    &:active {
      color: white;
      outline: none;
      border: none;
    }

    & span {
      margin-right: 1rem;
      &.active {
        color: $primary;
      }
    }
  }
}